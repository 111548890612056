@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  --primary-color: #1378be;
  --secondary-color: #00aeeb;
  --black-color: #333;
}
.toast-position {
  top: 6.5rem !important;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-col {
  flex-direction: column;
}
.theme_btn {
  margin-right: 20px;
  border: 0;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  padding: 18px 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.theme_btn .iconRight {
  font-size: 22px;
  margin-bottom: -6px;
}
.btn-primary {
  background-color: #1378be;
}
.btn-secondary {
  background-color: #2aadce;
}
.btn-white-border {
  color: #fff;
  padding: 17px 37.8px;
  background: transparent;
  border: 1.5px solid #fff;
}
.btn-transparent {
  color: #1378be;
  padding: 13.5px 31.1px;
  background: transparent;
  border: 1.5px solid #1378be;
}
.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.label h4 {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 19px;
  margin-left: 15px;
}
.chart-container {
  height: 80px;
  width: 80px;
}
.link {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: var(--color-text);
}
.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 3px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
  border-radius: 4px;
}
.link::before {
  content: "";
}
.link--metis {
  text-transform: capitalize;
}
.link--metis::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}
.link--metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}
.linkbtn {
  border-bottom: 2px solid #fff;
}
